$(document).ready(function() {
    // Удаляет класс first у всех пунктов меню с этим классом, если найден хоть один пункт с классом current
    if ($('nav.main .dropdown_menu').find('.current').length) {
        $('nav.main .dropdown_menu .menu__item').removeClass('first');
    }

    // Показывает/скрывает меню каталога при нажатии на кнопку
    $('header nav.main .catalog__button').click(function() {
        $(this).parents('header').find('nav.main').toggleClass('active');
        $(this).toggleClass('active');
    });

    //
    $('nav.main .dropdown_menu').mouseleave(function() {
        $(this).find('.submenu').removeClass('active');
        $('nav.main .dropdown_menu .current').removeClass('nonactive');
        $('nav.main .dropdown_menu .first').removeClass('nonactive');
        $(this).find('.menu__item').removeClass('active');
    });

    $('nav.main .dropdown_menu .submenu_toggle').mouseenter(function() {
        $('nav.main .dropdown_menu .submenu_toggle').removeClass('active');
        $(this).parents('.dropdown_menu').find('.submenu').removeClass('active');
        $(this).addClass('active');
        $(this).next('.submenu').addClass('active');
        $('nav.main .dropdown_menu .current').not(this).addClass('nonactive');
        $('nav.main .dropdown_menu .first').not(this).addClass('nonactive');
        setTimeout(function(){
            $(this).removeClass('active');
        }, 5000);
    });

}); 