$(document).ready(function() {
    // START Increment / Decrement Product Quantity //
    $(".product_quantity .quantity__item").click(function(){
        var quantity = parseInt($(this).siblings('.count').val());
        if ($(this).attr('data-type') == 'plus') {
            $(this).siblings('.count').val(quantity + 1).trigger('propertychange');
        } else if (quantity != 1) {
            $(this).siblings('.count').val(quantity - 1).trigger('propertychange');
        }
    });
    // END Increment / Decrement Product Quantity //

    // START Check Quantity Update //
    $('.product_quantity .count').on('input propertychange paste', function() {
        var id = $(this).attr('data-product_id');
        var key = $(this).attr('data-product_key');
        var type = $(this).attr('data-type');

        var quantity = $(this).val();
            //переделать это условие
        
        if (quantity > 1000) {
            $(this).val(1000);
            quantity = 1000;
        } else if (quantity > 1) {
        } else {
            $(this).val(1);
            quantity = 1;
        }

        if (type == 'cart') {
            updateCart('cart/change', key, quantity);
            setTimeout(function() {
                updatePrice(type, key);
            }, 500);
        }
    });
    // END Check Quantity Update //




    // START Compare //
    $('body').on('click','.compare__button',function(){
        var product_id = $(this).attr('data-product_id');
        var data = {};
        data['product_id'] = product_id;
        if ($(this).hasClass('active')) {
            data['action'] = 'remove_from_compare';
        } else {
            data['action'] = 'add_to_compare';
        }
        $.ajax({
            url: "/ajax",
            type: "POST",
            data: data,
            context: this,
            success: function (response) {
                $(this).toggleClass('active');
                if ($(this).hasClass('active')) {
                    $(this).attr('title', "Убрать из сравнения");
                    $.jGrowl("Товар добавлен в сравнение",{ theme: 'ms2-message-success' });
                } else {
                    $(this).attr('title', "Добавить в сравнение");
                    $.jGrowl("Товар убран из сравнения",{ theme: 'ms2-message-success' });
                }
            }
        });
    });
    // END Compare //

    // START Выравнивание опций по высоте для блоков товаров на странице сравнения //
    $('.content__block table').each(function() {
        var table = $(this);
        var thead_items;
        if (table.find('th').length > 0) {
            thead_items = table.find('th');
        } else {
            thead_items = table.find('thead td');
        }

        var max_options_height = [];
        $('.product__item___compare').each(function(index) {
            var products_counter = index;
            var product = $(this);
            product.find('.options__item').each(function(index) {
                var options_counter = index;
                if ($(this).height() > max_options_height[options_counter] ) {
                    max_options_height[options_counter] = $(this).height();
                }
            });
        });
        $('.product__item___compare').each(function() {
            var product = $(this);
            $.each(max_options_height, function(index, value) {
                var product_option = product.find('.options__item').eq(index);
                product_option.height(value);
            });
        });
    });
    // END Выравнивание опций по высоте для блоков товаров на странице сравнения //

    // START Favorites //
    $('body').on('click','.favorites__button',function(){
        var product_id = $(this).attr('data-product_id');
        var data = {};
        data['product_id'] = product_id;
        if ($(this).hasClass('active')) {
            data['action'] = 'remove_from_favorites';
        } else {
            data['action'] = 'add_to_favorites';
        }
        $.ajax({
            url: "/ajax",
            type: "POST",
            data: data,
            context: this,
            success: function (response) {
                $(this).toggleClass('active');
                if ($(this).hasClass('active')) {
                    $(this).attr('title', "Убрать из избранного");
                    $.jGrowl("Товар добавлен в избранное",{ theme: 'ms2-message-success' });
                } else {
                    $(this).attr('title', "Добавить в избранное");
                    $.jGrowl("Товар убран из избранного",{ theme: 'ms2-message-success' });
                }
            }
        });
    });
    // END Favorites //


    // START Add Product To Cart //
    $('body').on('click','.add_to_cart__button',function(){
        var id = $(this).attr('data-product_id');
        var quantity = $(".product__section .product_quantity .count").val();

        //переделать это условие
        if (quantity > 1) {
        } else {
            quantity = 1;
        }

        updateCart('cart/add', id, quantity);
        updateCount('cart', quantity);
        //updatePrice('minicart', id);
    });
    // END Add Product To Cart //

    // START Remove Product From Cart //
    $('.product_remove__button').click(function(){
        var product = $(this).parents('.cart__item');
        var product_id = product.attr('data-product_id');
        var key = product.attr('id');

        updateCart('cart/remove', key);
        if ($('.cart__item').length >= 2) {
            product.remove();
            setTimeout(function() {
                updatePrice('cart', key);
            }, 500);

        } else {
            $('.cart__block').empty();
            $('.cart__block').append('<h3>В корзине пусто</h3>')
        }
    });
    // END Remove Product From Cart //

    // Устанавливает актуальную цену после изменения данных в корзине
    function updatePrice(type, key) {
        var data = {};
        data['type'] = type;
        data['action'] = 'getPrice';
        if (type == 'cart') {
            // get product price by id
            // set product price
            // reset total price
            data['key'] = key;
        }
        $.ajax({
            url: "/ajax",
            type: "POST",
            data: data,
            success: function (response) {
                response = JSON.parse(response);
                if (data['type'] == 'cart') {
                    if (response.price) {
                        $('#' + data['key'] + ' .prices .price').text(response.price);
                    }
                    if (response.old_price) {
                        $('#' + data['key'] + ' .prices .old_price').text(response.old_price);
                    }
                    if (response.discount_price) {
                        $('.cart__block .cart__totals .details .discount_price .value').text(response.discount_price);
                    }
                    if (response.products_price) {
                        $('.cart__block .cart__totals .details .products_price .value').text(response.products_price);
                    }
                    if (response.total_price) {
                        $('.cart__block .cart__totals .details .total_price .value').text(response.total_price);
                    }
                }
            }
        });
    }

    // Устанавливает количество в счетчики: товаров в корзине / избранных товаров / товаров в сравнении 
    function updateCount(type, quantity) {
        $('.counter__' + type).text(quantity);
    }

    // Обновляет данные в коризне
    function updateCart(action, key, quantity = 0) {
        var data = {};
        data['ms2_action'] = action;
        data['count'] = quantity;
        if (action == 'cart/add') {
            data['id'] = key;
        } else {
            data['key'] = key;
        }
        $.ajax({
            url: "/",
            type: "POST",
            data: data,
            success: function (response) {
                response = JSON.parse(response);
                updateCount('cart', response.data.total_count);
                if (data['ms2_action'] == 'cart/add') {
                    $.jGrowl("Товар добавлен в корзину",{ theme: 'ms2-message-success' });
                } else if (data['ms2_action'] == 'cart/remove') {
                    $.jGrowl("Товар удален из корзины",{ theme: 'ms2-message-success' });
                }
            }
        });
    }

    // START Order Product //
    $(".order__button").on("click", function(e) {
        console.log('sdfsd');
        var id = $(this).attr('data-product_id');
        var quantity = $(".product__section .product_quantity .count").val();

        //переделать это условие
        if (quantity > 1) {
        } else {
            quantity = 1;
        }

        updateCart('cart/add', id, quantity);

        setTimeout(function() {
           window.location = "checkout";
        }, 1000);
        
    });
    // END Order Product //


    // START Product Content Tabs //
    $(".product__section .tabs__list .tabs__item").click(function(){
        $(".product__section .tabs__item").removeClass('active');
        $(this).addClass('active');
        var active_tab = $(this).attr('data-tab');
        $(".product__section .tabs__content").find('#' + active_tab).addClass('active');
    });
    // END Product Content Tabs //


});