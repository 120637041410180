$(document).ready(function() {
    // START Phone Mask //
    $("input[name=phone]").mask("+7 (999) 999-99-99", { placeholder: "+7 (___) ___-__-__" });
    // END Phone Mask //

    // START Compilations Block //
    $(".compilations__block .links__item").click(function(){
        if ($(this).attr('data-compilation')) {
            $('.compilations__block .links__item').removeClass('current');
            $(this).addClass('current');
            var data = {};
            data['action'] = 'compilations';
            data['type'] = $(this).attr('data-compilation');
            $.ajax({
                type: "post",
                url: "/ajax",
                data: data,
                success: function(response){
                    $(".compilations__block .compilations .swiper-wrapper").html(response);
                }
            });
        }
    });
    // END Compilations Block //

    // START Filter Block //
    $(".filter__block .props__item .title").click(function(){
        $(this).toggleClass('closed')
    });
    $(".filter__block .buttons .reset").click(function(){
        $(".filter__block .props_values__item").removeClass('checked');
        $('.filter__block .props_values__item___checkbox .checkbox').removeAttr('checked');
        $(".filter__block .filter__form").reset();
    });
    // END Filter Block //

    // START Sort Block //
    //// START Sort Params ////
    $(".sort__block .sort_params .title").click(function(){
        $(this).parent().toggleClass('active');
    });
    // Скрытие выпадающего списка параметров сортировке при клике вне этого списка
    $('body').mouseup(function (e) {
        var container = $(".sort__block .sort_params .sort__items");
        if (container.has(e.target).length === 0 && container.parent().has(e.target).length === 0){
            container.parent().removeClass('active');
        }
    });
    $(".sort__block .sort__item").click(function(){
        $(this).parent().find(".sort__item").removeClass('selected');
        $(this).addClass('selected');
        var data = {};
        data['sort_type'] = $(this).parent().attr('id');
        data['sort'] = $(this).attr('data-sort');
        if ($(this).attr('data-dir')) {
            data['sort_dir'] = $(this).attr('data-dir');
        }

        $.ajax({
            type: "post",
            url: window.location,
                data: data,
                success: function(response) {
                    location.reload();
            }
        });
    });
    //// END Sort Params ////
    // END Sort Block //


    const formClasses = ['.callback__form', '.call_request__form', '.call_request_2__form'];

    formClasses.forEach(function(formClass) {
        $(formClass + ' input').on('change', function() {
            validateField($(this));
        }); 

        $(formClass + ' .submit').on('click', function() {
            $(this).closest('form').find('input').each(function() {
                validateField($(this));
            });
        }); 
    });


    function validateField(input) {
        input.removeClass('validation_error');
        input.removeClass('validation_success');
        error_message = '';
        var value = input.val().trim();

        var error_message = '';

        switch (input.attr('name')) {
            case 'name':
                var namePattern = /^[a-zA-Zа-яА-Я]+$/;
                if (value === '') {
                    error_message = 'Поле обязательно для заполнения.';
                    input.addClass('validation_error');
                } else if (value.length < 2) {
                    error_message = 'Поле не может содержать менее 2 символов.';
                    input.addClass('validation_error');
                } else if (!namePattern.test(value)) {
                    error_message = 'Поле не должно содержать недопустимые символы.';
                    input.addClass('validation_error');
                } else {
                    input.addClass('validation_success');
                }
                break;

            case 'phone':
                var phonePattern = /^((8|\+7)[\- ]?)?(\(?\d{3,4}\)?[\- ]?)?[\d\- ]{5,10}$/;
                if (value === '') {
                    error_message = 'Поле обязательно для заполнения.';
                    input.addClass('validation_error');
                } else if (!phonePattern.test(value)) {
                    input.addClass('validation_error');
                } else {
                    input.addClass('validation_success');
                }
                break;
        }
        if (error_message != '') {
            $.jGrowl(error_message,{ theme: 'ms2-message-error' });
        }
    }


    // START Calc Form //
    //// START Calc Props ////
    $(".calc__form .props__item .props_values__value").click(function(){
        $('.calc__form .props__item').not($(this).parents('.props__item')).removeClass('active');
        $(this).parents('.props__item').toggleClass('active');
    });

    // Скрытие выпадающего списка параметров сортировке при клике вне этого списка
    $('body').mouseup(function (e) {
        var container = $(".calc__form .props__item .props_values__list");
        if (container.has(e.target).length === 0 && container.parents('.props__item').has(e.target).length === 0){
            container.parents('.props__item').removeClass('active');
        }
    });
    $(".calc__form .props_values__list .props_values__item ").click(function(){
        $(this).parent().find(".props_values__item").removeClass('selected');
        $(this).parents('.props__item').find('.props_values__value span').text($(this).attr('data-value'));
        $(this).parents('.props__item').find('input').val($(this).attr('data-value'));
        $(this).addClass('selected');
        $(this).parents('.props__item').removeClass('active');

        var data = {};
        data['action'] = 'calc';
        $('.calc__form .props__item input').each(function() {
            var name = $(this).attr('name');
            var value = $(this).val();
            if (value != '') {
                data[name] = value;
            }
        });
        $.ajax({
            type: "post",
            url: "/ajax",
            data: data,
            success: function(response){
                $(".calc__form .form_results").html(response);
            }
        });
    });
    //// END Calc Props ////
    // END Calc Form //

    // START Product Item Gallery //
    $(".product__item .gallery").mouseleave(function() {
        const $elements = $(this).find(".wrapper .image");
        $elements.length && ($elements.removeClass("active"), $($elements[0]).addClass("active"))
    })
    $('body').on('mouseenter','.product__item .gallery .image',function(){
        $(this).siblings().removeClass("active"), $(this).addClass("active")
    })
    // END Product Item Gallery //


    // START Checkout Options //
    if ($('.credentials .delivery_address').length) {
        $('.credentials .delivery_address').hide();
    }
    $(".checkout__item .options .options__item").click(function() {
        $(this).parent().find('.options__item').removeClass('active');
        $(this).addClass('active');

        if ($(this).find('input[name="delivery"]').length) {
            var checked_delivery = $(this).find('input[name="delivery"]:checked').val();
            if (checked_delivery == 1) {
                $('.credentials .delivery_address').hide();
            } else {
                $('.credentials .delivery_address').show();
            }
        }



    })
    // END Checkout Options //

    // START Footer Menu Open / Close //
    $("footer .menu .title").click(function() {
        $("footer .menu .menu__items").not($(this).siblings('.menu__items')).removeClass('active');
        $(this).siblings('.menu__items').toggleClass('active');
    })
    // END Footer Menu Open / Close //


    // START Адаптивное оформление таблиц контентного блока //
    $('.content__block table').each(function() {
        var table = $(this);
        var thead_items;
        if (table.find('th').length > 0) {
            thead_items = table.find('th');
        } else {
            thead_items = table.find('thead td');
        }
        thead_items.each(function(index) {
            var counter = index;
            var th = $(this);
            table.find('tbody tr').each(function() {
                var td = $(this).find('td').eq(counter);
                if (th.height() > td.height()) {
                    var source = th;
                    var dest = td;
                } else {
                    var source = td;
                    var dest = th;
                }
                dest.outerHeight(source.outerHeight(true));
            });
            table.find('tbody tr').each(function() {
                var td = $(this).find('td').eq(counter);
                if (th.height() > td.height()) {
                    var source = th;
                    var dest = td;
                } else {
                    var source = td;
                    var dest = th;
                }
                dest.outerHeight(source.outerHeight(true));
            });
        });
    });
    // END Адаптивное оформление таблиц контентного блока //

    // START Страница заказа
    $(".checkout__items .checkout__button").click(function(){
        var data = {};
        var temp = [];
        $('.checkout__items .payment__items').find('input').each(function() {
            if ($(this).val() != '') {
                if($(this).is(':checked')) {
                    data[this.name] = $(this).val();
                }
            }
        });
        $('.checkout__items .delivery__items').find('input').each(function() {
            if ($(this).val() != '') {
                if($(this).is(':checked')) {
                    data[this.name] = $(this).val();
                }
            }
        });
        $('.checkout__items .credentials').find('input').each(function() {
            if ($(this).val() != '') {
                if (!$(this).is(':radio')) {
                    data[this.name] = $(this).val();
                }
            }
        });
        $('.checkout__items .credentials').find('textarea').each(function() {
            if ($(this).val() != '') {
                data[this.name] = $(this).val();
            }
        });
        if (data['name'] && data['name'] != '') {
            data['receiver'] = data['name'];
            if (data['last_name'] && data['last_name'] != '') {
                data['receiver'] = data['receiver'] + ' ' + data['last_name'];
            }
        }
        data['ms2_action'] = 'order/submit';
        $.ajax({
            type: "POST",
            url: "/",
            data: data,
            success: function(response){
                response = JSON.parse(response);
                $('.checkout__items .credentials input').removeClass('error');
                if (response.success == true) {
                    $.jGrowl('Спасибо за Ваш заказ, мы рады, что Вы выбрали нас!', {theme: 'ms2-message-success', sticky: false});
                    setTimeout(function() {
                       window.location = "/";
                    }, 1000);
                } else {
                    if (response.message) {
                        $.jGrowl('Возникла ошибка при оформлении заказа. ' + response.message,{ theme: 'ms2-message-error' });
                    }
                    if (response.data) {
                        response.data.forEach(function(field) {
                            if (field == 'receiver') {
                                $('.checkout__items .credentials input[name="name"]').addClass('error');
                            } else {
                                $('.checkout__items .credentials input[name="' + field + '"]').addClass('error');
                            }
                        });
                    }
                }
            }
        });
    });
    /* END Страница заказа */
});
